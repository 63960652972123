
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import { RULES } from '@/components/forms'

@Component({
  components: { Agenda },
})
  export default class AgendaField extends Vue {
  @Prop({ type: Object, default: () => null }) value!: any
  @Prop({ type: [Object], default: () => null }) newAgenda!: any
  @Prop({ type: Array, default: () => [] }) agendas!: any[]
  @Prop({ type: Boolean, default: false }) required!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  selectedTime = ''
  fieldRequired = RULES.isRequired
  menuVisible = false

  mounted () {
    this.openMenu()
  }

  onTimeRangeSelected (range: { start: string; end: string }) {
    const { disabled } = this

    this.menuVisible = false

    if (disabled || !(range?.start && range?.end)) return
    this.selectedTime = `${range.start} - ${range.end}`
    this.$emit('time-range-selected', range)
  }

  openMenu () {
    this.menuVisible = true
  }

  @Watch('value', { immediate: true, deep: true })
  onValueChange (val) {
    if (val?.start && val?.end) {
      this.selectedTime = `${val.start} - ${val.end}`
    }

    if (val?.timeStart && val?.timeEnd) {
      this.selectedTime = `${val.timeStart} - ${val.timeEnd}`
    }
  }
  }
